import React from "react";

function Events({ driverFreight }) {
    return (
        <>
            <h5 className="mt-3 uppercase">Eventos da viagem</h5>
            <div className="overflow-auto">
                <table className="table-auto mt-6 w-full text-left ">
                    <thead className="border-b border-roxo_oficial">
                        <tr>
                            <th className="text-roxo_oficial">Motorista</th>
                            <th className="text-roxo_oficial">Veículo</th>
                            <th className="text-roxo_oficial">Latitude</th>
                            <th className="text-roxo_oficial">Longitude</th>
                            <th className="text-roxo_oficial">Status</th>
                            <th className="text-roxo_oficial">Modalidade</th>
                            <th className="text-roxo_oficial">Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {driverFreight &&
                                driverFreight?.events?.length > 0 &&
                                driverFreight.events.map((entity, key) => {
                                    return (
                                        <tr key={entity.userDriverName}>
                                            <td>{entity.vehicle.licensePlate}</td>
                                            <td>{entity.latitude}</td>
                                            <td>{entity.longitude}</td>
                                            <td>{entity.statusReadable}</td>
                                            <td>{entity.modality.description}</td>
                                            <td>{entity.occuredAt}</td>
                                        </tr>
                                    );
                                })}
                            {(!driverFreight || driverFreight?.events?.length === 0) && (
                                <tr className="text-center">
                                    <td className="text-center" colSpan="9">
                                        Sem eventos nesta viagem.
                                    </td>
                                </tr>
                            )}
                        </>
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Events;
